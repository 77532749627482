import React from 'react'

// Components
import Layout from '../components/layouts/default'

const NotFoundPage = () => (
  <Layout>
  <div className="container">
    <h1>HOPPLA!</h1>
    <p>Es gab leider ein Serverseitiges Problem.</p>
    <p>Gib uns gerne Bescheid, damit wir dir weiterhelfen können.</p>
  </div>
  </Layout>
)

export default NotFoundPage
